import clsx from 'clsx';
import { Show, mergeProps, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ImageWordpressBlock } from '../../types/shared';
import IntersectionObserver from '../intersection-observer/intersection-observer';
import { A } from '@solidjs/router';

const aspectRatioMap = {
    wide: '16/10',
    portrait: '10/16',
    square: '1',
    original: null,
    newsListImage: '3/2',
};

export type ImageComponentProps = {
    src: string;
    imageText?: string;
    altText?: string;
    ariaLabel?: string;
    width?: number | string;
    height?: number | string;
    jwt?: string;
    ratio?: number;
    threshold?: number;
    focus?: {
        y: number;
        x: number;
    };
    aspectRatio?: 'original' | 'wide' | 'portrait' | 'square' | 'newsListImage' | 'clients';
    roundCorners?: 'none' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'full';
    // blockSpacing?: boolean;
    flowWild?: boolean;
    offsetOption?: 'small' | 'big';
    imageSize?: 'original' | 'extra-wide' | 'limit-height';
    imageLinkURL?: string;
    openNewTab?: boolean;
    classes?: string;
    yMargins?: boolean;
    limitHeight?: string;
    renderWidth?: string;
    renderHeight?: string;
    topRounding?: boolean;
    noRoundCornersRightSide?: boolean;
};

type ExtendedImageWordpressBlock = ImageWordpressBlock & {
    flowWild: boolean;
    offsetOption: string;
    imageLinkURL: string;
    openNewTab: boolean;
    ariaLabel: string;
};

type SupportedImageFormatsType = {
    webp: boolean;
    avif: boolean;
};

type GetImageServiceSrcPathProps = {
    imagesServiceUrl: string;
    supportedImageFormats: SupportedImageFormatsType;
    src: string;
    jwt?: string | undefined;
    width: string | number;
    height: string | number;
};

type OffsetOption = 'small' | 'big';

const offsetOptions: Record<OffsetOption, string> = {
    big: '50px',
    small: '10px',
};

export const getImageServiceSrcPath = (props: GetImageServiceSrcPathProps) => {
    const host = props.imagesServiceUrl + '/image';
    let parsedSrc = props.src;

    if (!parsedSrc.match(/\.png$/)) {
        if (props.supportedImageFormats.avif && !parsedSrc.match(/\.avif$/)) {
            parsedSrc += '.avif';
        } else if (props.supportedImageFormats.webp && !parsedSrc.match(/\.webp$/)) {
            parsedSrc += '.webp';
        }
    }

    let imageUrl = `${host}/${props.width}x${props.height}${parsedSrc || '/'}`;

    if (props.jwt) {
        imageUrl += `?jwt=${props.jwt}`;
    }
    return imageUrl || '';
};

export type ImageSrcData = {
    original: string;
    retina: string;
};

export const getBackgroundImageSrcData = (props: GetImageServiceSrcPathProps): ImageSrcData => {
    const originalWidthToFetch = getImageWidthToFetch(props.width, false);
    const retinaWidthToFetch = getImageWidthToFetch(props.width, true);

    const originalPath = getImageServiceSrcPath({ ...props, width: originalWidthToFetch });
    const retinaPath = getImageServiceSrcPath({ ...props, width: retinaWidthToFetch });

    return {
        original: originalPath,
        retina: retinaPath,
    };
};

const getOffsetSize = (key: keyof typeof offsetOptions): string => {
    return offsetOptions[key];
};

const getImageWidthToFetch = (width: string | number | undefined, retina: boolean) => {
    let parsedWidth: string | number = 'auto';
    if (typeof width === 'string') {
        // Fetch a large image if width was passed as a percent
        if (width.includes('%')) {
            parsedWidth = 1200;
        } else if (width === 'AUTO') {
            parsedWidth = width;
        } else if (!isNaN(parseInt(width))) {
            parsedWidth = parseInt(width);
        } else {
            throw new Error('unsupported-width');
        }
    } else {
        parsedWidth = width || 'auto';
    }

    if (retina) {
        parsedWidth = typeof parsedWidth === 'number' ? parsedWidth * 2 : parsedWidth;
    }

    return parsedWidth;
};

const defaultProps = {
    roundCorners: 'l',
    spacer: true,
    src: '',
    imageSize: 'original',
    aspectRatio: 'original',
};

export const ImageComponent = (componentProps: ImageComponentProps) => {
    if (!componentProps.src) {
        return;
    }
    const props = mergeProps(defaultProps, componentProps);

    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);
    const retinaWidthToFetch = getImageWidthToFetch(props.width, true);
    const retinaHeightToFetch = !props.height || typeof props.height === 'string' ? 'AUTO' : props.height * 2;
    const originalWidthToFetch = getImageWidthToFetch(props.width, false);
    const originalHeightToFetch = !props.height || typeof props.height === 'string' ? 'AUTO' : props.height;

    let lowResImage: HTMLImageElement | undefined; //eslint-disable-line prefer-const
    let imageFig: HTMLElement | undefined; //eslint-disable-line prefer-const
    let imageWrapperRef: HTMLDivElement | undefined; //eslint-disable-line prefer-const
    let image: (HTMLImageElement & { loaded: boolean }) | undefined; //eslint-disable-line prefer-const
    let loadedOnce = false;

    let doneAnimating = false;

    onMount(() => {
        loadedOnce = false;

        if (image) {
            image.addEventListener(
                'animationend',
                () => {
                    if (!doneAnimating) {
                        doneAnimating = true;
                        // line above was commented out in atos !
                        // image?.classList.add('h-auto', 'static'); // .imageFullResTransitionEnded
                        if (lowResImage && image) {
                            lowResImage.classList.add('h-auto', 'static', 'block', 'hidden'); // .imageLowResTransitionEnded
                        }
                    }
                },
                false,
            );
        }
    });

    let done = false;
    let done2 = false;

    let roundedCornersClass: string | undefined;
    if (props.topRounding) {
        roundedCornersClass = 'rounded-' + props.roundCorners + ' rounded-bl-none rounded-br-none';
    }
    if (props.noRoundCornersRightSide) {
        roundedCornersClass = 'rounded-tr-none rounded-br-none rounded-tl-m rounded-bl-m';
    } else if (props.roundCorners && props.roundCorners !== 'none' && props.noRoundCornersRightSide === undefined && props.topRounding === undefined) {
        roundedCornersClass = 'rounded-' + props.roundCorners;
    }

    // This code makes sure we load the correct image behind the scenes so we can 
    // fade in the image when its loaded, not before.
    // Its a little bit complicated because:
    // 1. We need to check that the low low res image is loaded first.
    // 2. When we know its loaded, we need to begin to download our main image.
    // 3. We get the src from the lowres image, but since we have a sourceset, we actually get the highres src.
    // 4. We create an img tag and begin to load the highres image so that we know when its loaded without showing it
    // 5. We replace the src of the image with the highres and fade it in.
    // X. For some reason sometimes the "load" event doesnt trigger. IT might be because it takes too much time 
    //    for the image service to provide the image the first time. We fix this by having a setTimeout to check if its loaded.
    const visible = (isVisible: boolean) => {

        // If the image isnt in the viewport, dont bother
        if (!isVisible) {
            return;
        }

        if (!done && image && !image.loaded) {

            // This is when the lowres is done
            const lowResLoaded = () => {


                // Dont know if this is needed, but it has been here since the begining
                done = true;

                // Create an img tag to load the highres image in the background
                const img = new Image();

                // When everyting is loaded, we set the src to the highres image and we fade it in
                const markAsLoaded = () => {
                    if (!done2 && image) {
                        done2 = true;
                        image.src = image.currentSrc; //img.src;
                        image.classList.add('imageFullResLoaded');
                    }
                };

                // Listen to the load event of the img tag. 
                img.addEventListener('load', markAsLoaded, false);

                // The event listner above doesnt work 100%, so we actually add an extra check
                let loadCounter = 0;
                const extraCheck = () => {
                    if (img?.complete) {
                        markAsLoaded();
                    } else if (loadCounter++ < 10) {
                        setTimeout(extraCheck, 500);
                    }
                };
                setTimeout(extraCheck, 50);

                // Begin to load the highres image
                img.src = image?.currentSrc || '';

            };

            if (image.complete) {
                lowResLoaded();
            } else {
                image.addEventListener('load', lowResLoaded);
            }

        }
    };

    let ratio: string | number | undefined = undefined;
    ratio = !props.aspectRatio || props.aspectRatio === 'original' ? props.ratio : aspectRatioMap[props.aspectRatio];

    if (typeof ratio === 'number') ratio = ratio.toString();
    ratio = ratio || '1';

    const setImageFigHeight = () => {
        if (imageFig) {
            imageFig.style.aspectRatio = ratio;
            // props.roundCorners && imageFig.classList.add('rounded-3xl');
        }

        if (imageWrapperRef) {
            imageWrapperRef.style.aspectRatio = ratio;
        }
    };

    const loaded = () => {
        if (loadedOnce === false) {
            loadedOnce = true;
            setImageFigHeight();
        }
    };

    const getPictureElement = (altText: string, imgStyle: Record<string | number | symbol, never>, imageSize: string) => {
        const retinaSrc = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.src,
            jwt: props.jwt,
            width: retinaWidthToFetch,
            height: retinaHeightToFetch,
        });

        const src = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.src,
            jwt: props.jwt,
            width: originalWidthToFetch,
            height: originalHeightToFetch,
        });

        const srcSet = `${src}, ${retinaSrc} 2x`;

        const lowRes = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.src,
            jwt: props.jwt,
            width: 1,
            height: 1,
        });

        return (
            <picture class={clsx({ 'w-full': imageSize !== 'limit-height', 'w-auto': imageSize === 'limit-height' }, 'highres')}>
                <source srcset={srcSet} />
                <img
                    data-identifier="high-res-img"
                    title={altText}
                    style={imgStyle}
                    class={clsx(roundedCornersClass, props?.classes)}
                    ref={image}
                    alt={altText}
                    src={lowRes}
                />
            </picture>
        );

    };

    const convertDimensionPropToCss = (dimension: string | number | undefined) => {
        const units = ['px', '%', 'em', 'rem', 'vw', 'vh', 'vmin', 'vmax'];
        const unitUsed = units.find((unit) => dimension && dimension.toString().includes(unit));

        if (unitUsed) {
            // Dimension is a string with a unit, so we'll assume it's a valid CSS dimension
            return dimension;
        }

        if (typeof dimension === 'number') {
            // Dimension is a number, so we'll assume it's a number of pixels
            return `${dimension}px`;
        }

        if (dimension && !isNaN(parseInt(dimension))) {
            // Dimension can be converted to a number, so we'll assume it's a number of pixels
            return `${dimension}px`;
        }

        return 'auto'; // We don't recognise the dimension format, so we'll return 'auto'
    };

    const figureStyle: any = {
        height: props.renderHeight ? props.renderHeight : convertDimensionPropToCss(props.height),
        width: props.renderWidth ? props.renderWidth : convertDimensionPropToCss(props.width),
        'aspect-ratio': ratio,
        backgroundColor: 'transparent',
    };

    const imgStyle: any = {
        height: props.imageSize === 'limit-height' ? '100%' : convertDimensionPropToCss(props.height),
        width: props.imageSize === 'limit-height' ? 'auto' : '100%',
        display: 'none',
        objectFit: props.aspectRatio && props.aspectRatio !== 'original' ? 'contain' : 'cover', // Change here
        backgroundColor: 'transparent',
    };

    if (props.focus) {
        imgStyle['object-position'] = `${props.focus.x * 100}% ${props.focus.y * 100}%`;
    }

    if (props.aspectRatio && props.aspectRatio !== 'original') {
        imgStyle['aspect-ratio'] = aspectRatioMap[props.aspectRatio];
        imgStyle['object-fit'] = 'cover';
    }
    if (props.aspectRatio && props.aspectRatio === 'clients') {
        imgStyle['aspect-ratio'] = aspectRatioMap[props.aspectRatio];
        imgStyle['object-fit'] = 'contain';
    }

    const lowRes = getImageServiceSrcPath({
        imagesServiceUrl,
        supportedImageFormats,
        src: props.src,
        jwt: props.jwt,
        width: 1,
        height: 1,
    });

    const lowResImgStyle: any = { opacity: 1 };
    lowResImgStyle['aspect-ratio'] = ratio; //padding-bottom'] = convertDimensionPropToCss(props.height) !== 'auto' ? props.height : (1 / (props.ratio || 1)) * 100 + '%';
    lowResImgStyle.background = `url(${lowRes})`;

    const ImageLowResComponent = () => {
        return (
            <img
                data-identifier="low-res-img"
                style={lowResImgStyle}
                ref={lowResImage}
                onLoad={loaded}
                class="animate-image--animate animate-0.5s animate-forwards absolute left-0 top-0 w-full object-cover opacity-0 ease-out"
                src={lowRes}
                alt={props.altText}
            />
        );
    };

    const ImageHighResComponent = () => getPictureElement(props.altText, imgStyle, props.imageSize);

    const imageWrapperStyle: any = {
        // width: !props.renderWidth && props.width, // If images start looking wierd, maybe re-add this, but I think it should be gone
    };
    if (props.flowWild) {
        imageWrapperStyle.left = getOffsetSize(props.offsetOption);
    }

    // const imageWrapperClassName = `h-fit w-full ${props.blockSpacing ? 'mb-2 tablet:mb-3' : ''}`;

    const extraWideClasses = ' tablet:w-[120%] tablet:max-w-[100vw] tablet:ml-[50%] tablet:-translate-x-[50%] tablet:px-4';
    const limitHeightClasses = ' max-w-[100%] w-auto max-h-[20vh]';

    let imageWrapperClassName = 'h-fit w-full';

    if (props.yMargins) {
        imageWrapperClassName += ' my-12';
    }

    props.imageSize === 'extra-wide' && (imageWrapperClassName += extraWideClasses);
    props.imageSize === 'limit-height' && (imageWrapperClassName += limitHeightClasses);

    const observerClassName = clsx(
        { 'h-auto': props.imageSize !== 'limit-height' },
        { 'w-full': props.imageSize !== 'limit-height' },
        { 'w-auto': props.imageSize === 'limit-height' },
        { 'h-full': props.imageSize === 'limit-height' },
        { absolute: props.flowWild },
    );

    const figureClasses = clsx('flex', 'relative', 'overflow-hidden', 'justify-center', roundedCornersClass, { 'h-full': props.imageSize === 'limit-height' });

    if (componentProps.src.match(/\.svg$/)) {
        figureStyle['aspect-ratio'] = '';
        if (props.imageSize === 'limit-height') {
            figureStyle.height = null;
        }
        return (
            <div ref={imageWrapperRef} class={imageWrapperClassName}>
                <figure ref={imageFig} style={figureStyle} class={figureClasses}>
                    <img src={componentProps.src} />
                </figure>
            </div>
        );
    }

    const imageWrapper = (
        <div ref={imageWrapperRef} class={imageWrapperClassName}>
            <IntersectionObserver
                className={observerClassName}
                style={imageWrapperStyle}
                root=".main-content"
                onVisible={visible}
                threshold={props.threshold || 0.1}
            >
                <figure ref={imageFig} style={figureStyle} class={figureClasses}>
                    {props.src.match(/\.png/) || props.imageSize === 'limit-height' ? null : <ImageLowResComponent />}
                    <ImageHighResComponent />
                </figure>
                <Show when={props.imageText && props.imageText !== ''}>
                    <span class="paragraphXSMobile tablet:paragraphXS mt-2 inline-block w-full text-center">{props.imageText}</span>
                </Show>
            </IntersectionObserver>
        </div>
    );

    return (
        <ErrorCatcher componentName="Image component">
            {/* ImageWrapper */}
            <Show when={props.imageLinkURL} fallback={imageWrapper}>
                <A href={props.imageLinkURL!} target={props.openNewTab ? '_blank' : '_self'} aria-label={props.ariaLabel}>
                    {imageWrapper}
                </A>
            </Show>
        </ErrorCatcher>
    );
};

ImageComponent.parseProps = (atts: ExtendedImageWordpressBlock) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.
    const { image } = atts;

    return {
        src: image?.url,
        // Always set this to 100% when we're dealing with a gutenberg image.
        width: '100%',
        ratio: image?.ratio,
        jwt: image?.jwt,
        focus: image?.focus,
        altText: atts?.altText,
        ariaLabel: atts?.ariaLabel,
        imageText: atts?.imageText,
        imageLinkURL: atts?.imageLinkURL,
        yMargins: true,
        aspectRatio: atts?.aspectRatio,
        imageSize: atts?.imageSize,
        // blockSpacing: atts?.blockSpacing,
        flowWild: atts?.flowWild,
        offsetOption: atts?.offsetOption,
        openNewTab: atts?.openNewTab,
    };
};
