import { WrappedImage } from '../../components/image-component/wrapped-image';

import clsx from 'clsx';
import { Text } from '../../ui-components/text/text';
import { ClientType } from './clients-types';
import { For } from 'solid-js';

export const ClientSelector = (atts: any) => {

    const { clients = [], showTitles } = atts;

    return (
        <div class="container mx-auto">
            {clients.length > 0 ? (
                <ul class="grid grid-cols-2 gap-8 tablet:grid-cols-3 bigTablet:grid-cols-3 laptop:grid-cols-3 desktop:grid-cols-4 desktopXL:grid-cols-5">
                    <For each={clients}>
                        {(client: ClientType) => {
                            return (
                                <li class="w-full">
                                    <a
                                        href={client.client_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="group block"
                                        aria-label={client.client_name}
                                    >
                                        <div class="mb-s">
                                            {client.featured_image && (
                                                <div
                                                    class={clsx(
                                                        'group-hover:blur-none',
                                                        'group-focus:blur-none',
                                                        'overflow-hidden rounded',
                                                        'transition-transform duration-300 ease-in-out',
                                                        'hover:scale-105',

                                                    )}
                                                >
                                                    <WrappedImage
                                                        width={300}
                                                        renderWidth={'100%'}
                                                        renderHeight={'100%'}
                                                        height={'auto'}
                                                        aspectRatio={'clients'}
                                                        image={client.featured_image}
                                                        classes="object-contain w-full h-full "
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {showTitles && (
                                            <Text classes="mb-[0.25em] text-center paragraphS">
                                                {client.client_name}
                                            </Text>
                                        )}
                                    </a>
                                </li>
                            );
                        }}
                    </For>
                </ul>
            ) : ( <div></div>)
            }
        </div>
    );
};

ClientSelector.parseProps = (atts: any) => {
    return {
        clients: atts.clients ?? [],
        showTitles: atts.showTitles ?? true, 
    };
};
