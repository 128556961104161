import { A } from '@solidjs/router';
import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { WrappedImage } from '../image-component/wrapped-image';

export const ProjectListItem = (props: any) => {

    return (
        <ErrorCatcher componentName="BlogListItem">
            <article class="post-list-item">
                <A class="group" href={props.post.permalink}>
                    <div class="">
                        <div class="mb-s">
                            {props.post.featured_image && (
                                <div class={clsx('group-hover:blur-none', 'group-focus:blur-none')}>
                                    <WrappedImage
                                        width={300}
                                        renderWidth={'100%'}
                                        height={'auto'}
                                        aspectRatio={'wide'}
                                        image={props.post.featured_image}
                                        classes={clsx('post-list-item-image group-hover:scale-105 ease-in-out duration-300')}
                                    />
                                </div>
                            )}
                        </div>

                        <Heading classes="mb-[0.25em]" fontSize="l" tag="h3">
                            {props.post.post_title}
                        </Heading>
                        {props.post.list_description && <Text fontSize="m">{props.post.list_description}</Text>}
                        {props.post.project_client}
                    </div>
                </A>
            </article>
        </ErrorCatcher>
    );
};

export type BlogListItemProps = {
    post: {
        featured_image: string;
        permalink: string;
        post_title: string;
        list_description: string;
        project_client: number; 
    };
};
