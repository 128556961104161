import { For, Show } from 'solid-js';
import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ReadMoreItem } from './read-more-item/read-more-item';
import { ImageWordpress } from '../../types/shared';


export type ReadMoreProps = {
    selectedPosts: {
        post_title: string;
        featured_image: ImageWordpress;
        preamble: string;
        permalink: string;
    }[];
    index: number;
    blockName: string;
    hash: string;
};

export const ReadMore = (componentProps: ReadMoreProps) => {
    return null;
    const postsContent = componentProps.selectedPosts;
    const loadingClasses = clsx('min-h-[200rem]');

    return (
        <ErrorCatcher componentName="ReadMoreArticles">
            <Show
                when={postsContent && postsContent.length > 0}
                fallback={<div class={loadingClasses}></div>}
            >
                <section class="post-list gap-y-s gap-x-m grid grid-cols-1 mobile:grid-cols-2 bigTablet:grid-cols-3">
                    <For each={postsContent}>
                        {(post) => (
                            <article class="mb-xl">
                                <ReadMoreItem
                                    permalink={post.permalink}
                                    postTitle={post.post_title}
                                    preamble={post.preamble}
                                    featuredImage={post.featured_image}
                                />
                            </article>
                        )}
                    </For>
                </section>
            </Show>
        </ErrorCatcher>
    );
};

export default ReadMore;