import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Menu } from '../menu/menu';
import { createSignal, onCleanup, onMount } from 'solid-js';

export const HeaderContainer = () => {
    const [scrolled, setScrolled] = createSignal(false);

    const handleScroll = () => {
        setScrolled(window.scrollY > 40);
    };
    onMount(() => {
        window.addEventListener('scroll', handleScroll);
        onCleanup(() => window.removeEventListener('scroll', handleScroll));
    });

    return (
        <ErrorCatcher componentName='Header'>
            {scrolled()}
            <header class={clsx('sticky top-0 w-full z-50', scrolled() ? 'backdrop-blur shadow-tiny' : '')}>
                {/* TODO: fix props */}
                <Menu />
            </header>
        </ErrorCatcher>
    );
};
