import { A } from '@solidjs/router';
import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { WrappedImage } from '../image-component/wrapped-image';

export const BlogListItem = (props: any) => {
    return (
        <ErrorCatcher componentName="BlogListItem">
            <article class="post-list-item">
                <A class="group" href={props.post.permalink}>
                    <div>
                        <div class={clsx('mb-s', { 'tablet:mb-[1.25rem]': props.readMore })}>
                            {props.post.featured_image ? (
                                <div class="overflow-hidden">
                                    <WrappedImage
                                        width={300}
                                        renderWidth={'100%'}
                                        height={'auto'}
                                        aspectRatio={'wide'}
                                        image={props.post.featured_image}
                                        classes={clsx('post-list-item-image group-hover:scale-105 ease-in-out duration-300')}
                                    />
                                </div>
                            ) : (
                                <div class="aspect-video w-[300px]" />
                            )}
                        </div>
                        <h3 class={clsx('mb-[0.25em]', 'text-headerL', 'font-paytone', 'w-full', 'max-w-textMaxWidth', { 'tablet:text-headerS': props.readMore })}>
                            {props.post.post_title}
                        </h3>
                        {props.post.list_description && (
                            <p
                                class={clsx('w-full', 'max-w-textMaxWidth', 'text-left', 'text-paragraphM', 'mb-[1em]', {
                                    'tablet:text-paragraphS': props.readMore,
                                })}
                            >
                                {props.post.list_description}
                            </p>
                        )}
                    </div>
                </A>
            </article>
        </ErrorCatcher>
    );
};

export type BlogListItemProps = {
    post: {
        featured_image: string;
        permalink: string;
        post_title: string;
        list_description: string;
    };
};
